export default {
  hu: {
    siteName: window.location.hostname.replace(/^www\./i, '').split('.').map((v, i, a) => i < a.length - 1 ? v.toUpperCase() : v).join('.'),
    dataPrivacyLink: 'https://www.bmw-motorrad.hu/hu/public-pool/content-pool/privacy.html',
    simpleHeadline: `Cookie-k a {{i18n.siteName}} oldalain.`,
    simpleDisclaimer: (
      `A {{i18n.siteName}} weboldalain Cookie-kat használunk a lehető legjobb
      felhasználói élmény érdekében. A Cookie-k szükségesek az oldalaink működésének biztosítása,
      használatuk megkönnyítése, az oldalakon végzett műveletek nyomon követése, szolgáltatások
      javítása és releváns ajánlatok megjelenítése érdekében. Valamennyi Cookie
      nélkülözhetetlen szolgáltatásaink biztosításához. Egyéb Cookie-k - beleértve a harmadik
      féltől származóakat -, kikapcsolhatók, azonban nélkülük oldalunk nem működik teljes értékűen.
      Az elfogadás gombra kattintva
      beleegyezését adhatja, hogy Cookie-kat használjunk. A nyilatkozatot bármikor megváltoztathatja
      a láblécből elérhető "Cookie beállítások" oldalunkon.
    `
    ),
    advancedHeadline: 'Az oldal cookie beállításai.',
    advancedDisclaimer: (
      'Kérjük, válassza ki, hogy oldalunk milyen cookie-kat használjon, az alábbiak szerint:'
    ),
    keepTheSettingsAndClose: 'Beállítások megtartása',
    cookiePolicy: (
      `
        <article>

          <h1>A BMW COOKIE-KKAL KAPCSOLATOS POLITIKÁJA.</h1>
          <h2>COOKIE-K HASZNÁLATA A BMW-NÉL.</h2>
          <p>
            A cookie egy kisméretű szöveges fájl, amelyet bizonyos weboldalak
            tárolnak a számítógépeden. A weboldalhoz kapcsolódó cookie-k
            használatának célja, hogy az ügyfelek élvezetesebben böngészhessenek.
            A BMW Group komolyan veszi a magánszférád védelmét. Az alábbiakban
            részletes információkat találsz az általunk használt cookie-król,
            azok telepítésének megakadályozásáról, illetve az utóbbi döntés
            böngészési élményre gyakorolt hatásáról.
          </p>
          <h3>A COOKIE-K HASZNÁLATÁNAK ÁLTALÁNOS ENGEDÉLYEZÉSE/TILTÁSA.</h3>
          <p>
            Ön böngészője beállításainak segítségével engedélyezheti vagy tilthatja le a cookie-k
            használatát. Felhívjuk azonban a figyelmét arra, hogy az oldal nem mindegyik interaktív
            funkciója lesz használható akkor, ha a cookie-k használatának tiltását választja.
            A legújabb böngészők a böngésző-beállításokon keresztül biztosítják a süti-használat
            szabályozását. Ha még többet szeretne megtudni a cookie-król – például arról, hogy
            miként tekintheti meg, rendezheti és törölheti a számítógépére elmentett cookie-kat –,
            kérjük, látogasson el
            <a rel="nofollow noopener" href="http://www.youronlinechoices.com/hu/" target="_blank">www.youronlinechoices.com</a>
              weboldalra.
          </p>
          <p>
            Megjegyzés: Lehetséges hogy cookie-k azelőtt kerülnek eszközén eltárolásra, mielőtt
            elutasítaná őket. Technikai okokból csak azokat a cookie-kat tudjuk törölni, amelyek
            általunk kerültek létrehozásra, így maradhatnak az eszközön harmadik féltől származó
            cookie-k. A böngészője lehetőséget ad ezen cookie-k  eltávolítására az adatkezelési
            beállításoknál.
          </p>

          <h3>MI A COOKIE?</h3>

          <p>
          A cookie egy kis szöveges fájl, amely internetes beállításokat tárol. Szinte minden
          weboldal alkalmazza a cookie technológiát. A cookie-t akkor tölti le a böngészője, amikor
          egy weboldalt először meglátogat. Amikor legközelebb meglátogatja a weboldalt ugyanazzal
          az eszközzel, akkor a cookie és az abban található információk elküldésre kerülnek vagy
          az eredeti weboldalra (belső cookie-k vagy első fél cookie-k) vagy egy másik weboldalra,
          amelyhez az eredeti tartozik (külső cookie-k vagy harmadik fél cookie-k). A weboldal így
          felismeri, hogy az adott böngészővel megnyitották-e már korábban, és bizonyos esetekben
          módosíthatja is a megjelenített tartalmat.
          </p>

          <p>
            Egyes cookie-k nagyon hasznosak lehetnek, mert egy már meglátogatott
            weboldalra való visszatéréskor javítják a felhasználói élményt. Ehhez ugyanazzal a
            készülékkel és böngészővel kell újra megnyitni az oldalt; ez esetben a cookie-k
            emlékezni fognak a preferenciáidra, valamint hogy hogyan használod az oldalt, és a
            bemutatott tartalmat az érdeklődésednek és igényeidnek megfelelően alakítja ki.
          </p>

          <p>
            Az alábbi táblázatok bemutatják a cookie-kat, illetve ismertetik azok használatának
            célját. Az információkat rendszeresen frissítjük, mégis előfordulhat, hogy valamilyen
            eltérést tapasztal. Kérjük, hogy utóbbi esetben vegye fel velünk a kapcsolatot.
          </p>

          <h2>SZIGORÚAN SZÜKSÉGES COOKIE-K.</h2>
          <h3>A WEBOLDAL OLYAN COOKIE-JAI, MELYEKHEZ NINCS SZÜKSÉG A BELEEGYEZÉSÉRE:</h3>

          <p>
            Az elengedhetetlenül fontos cookie-k, melyeket "szigorúan szükséges"-nek is neveznek,
            olyan funkciókat tesznek lehetővé, melyek nélkül nem lehetne megfelelően használni a
            weboldalt. Egy példa arra, hogy miért használunk szigorúan szükséges cookie-kat: amikor
            megnyitja a jármű-konfigurátort, annak olyan adattartalmú változata fog betöltődni,
            amelyet az Ön aktuális internetkapcsolata biztosan kezelni tud. Egy másik példa az ilyen
            cookie-k rendeltetésére, hogy az oldalak közötti váltás során lehetővé teszik a http-ről
            a https-re való átváltást, hogy biztosított legyen a továbbított adatok biztonsága.
            Emellett a cookie-k használatával kapcsolatban hozott döntését is ilyen típusú cookie-k
            tárolják.
          </p>

          <p>
            A szigorúan szükséges cookie-k használatához jogi értelemben nincs szükség az
            Ön beleegyezésére. A szigorúan szükséges cookie-k lengedhetetlenül fontosak ahhoz,
            hogy a felhasználó navigálhasson a weboldalon és használhassa a funkcióit. Ezen cookie-k
            nélkül bizonyos szolgáltatások nem működnének – úgy mint az előzőleg elvégzett
            tevékenységekre való emlékezés (pl. bevitt szövegek, jármű-konfigurációk) egy
            munkameneten belül egy előző oldalra való visszatérésnél.
          </p>

          {{components.cookie_table_cookies_essential}}

          <h3>A WEBOLDAL OLYAN COOKIE-JAI, MELYEKHEZ SZÜKSÉG VAN A BELEEGYEZÉSÉRE:</h3>
          <p>
            Olyan cookie-k, melyek szigorúan jogi értelemben nem feltétlenül szükségesek a
            weboldal megfelelő használatához, ennek ellenére fontos funkcióik vannak.
            Ezen cookie-k nélkül nem fognak működni a weboldalunk egyszerű használatát lehetővé
            tevő funkciók. Az Ön által megadott beállításokat, például a nyelv kiválasztását a
            weboldal nem fogja elmenteni, így minden alkalommal, amikor lapot vált, újra meg fogja
            kérdezni. A BMW harmadik félhez tartozó tartalmat is beépített ebbe a weboldalba.
            Ilyen például a videótartalmak weboldalba való beépítése. Ezek a harmadik felek a BMW
            weboldal böngészése során elméletileg cookie-kat helyezhetnek el, és ezzel megtudhatják,
            hogy Ön meglátogatta a BMW weboldalát. Ha szeretne többet megtudni arról, hogy a
            harmadik felek hogyan alkalmaznak cookie-kat, kérjük, keresse fel az ő weboldalaikat.
            <br />
            Ha Ön úgy döntött, hogy nem egyezik bele az olyan cookie-k használatába, melyekhez a
            beleegyezése szükséges, illetve ha visszavonta a beleegyezését, akkor a weboldalnak
            csak azon funkcióit fogja tudni használni, melyekhez nincs szükség a cookie-kra.
            Emellett a weboldal olyan részeit, melyekbe harmadik felek tartalma van beépítve, és
            ahol így esetleg harmadik fél cookie-jának elhelyezése előfordulhat, Ön nem fogja elérni,
            ezzel megakadályozva a harmadik felek cookie-jainak elhelyezését. Erről tájékoztatást
            fog kapni. Ha ez esetben Ön mégis úgy dönt, hogy szeretné használni az adott webes
            tartalmat, akkor ezt megteheti, ha beleegyezik a cookie-k használatába, beleértve az
            esetleg harmadik felek (külső) cookie-jait. Ehhez kérjük, aktiválja a weboldalunk
            megfelelő funkcióját.
            <br />
            Ha egyáltalán nem szeretne cookie-kat elfogadni, azt is be tudja állítani a böngészőjében.
          </p>

          <h2>FUNKCIONÁLIS COOKIE-K.</h2>

          <p>
            A funkcionális cookie-k lehetővé teszik a weboldal számára a már megadott
            információk elmentését (például felhasználói nevek, nyelv kiválasztása, az Ön helye),
            így a weboldal jobb, személyre szabottabb funkciókat tud kínálni Önnek. A weboldal
            például a helyileg érvényes, ártól függő adózási információkat biztosíthat az Ön régióját
            ismerő cookie használatával. A funkcionális cookie-k teszik lehetővé az Ön által kért
            funkciók működését is, mint pl. a videók lejátszását. Ezek a cookie-k anonim
            információkat gyűjtenek, és nem tudják nyomon követni azt, amikor Ön más weboldalakon
            böngész.
          </p>

          {{components.cookie_table_cookies_functional}}

          <h2>TELJESÍTMÉNY COOKIE-K.</h2>

          <p>
            A teljesítmény cookie-k arról gyűjtenek információkat, hogy a weboldalt hogyan
            használják – például hogy mely oldalakat nyitja meg a leggyakrabban a felhasználó,
            illetve hogy kap-e hibaüzeneteket oldalaktól. Ezek a cookie-k nem tartalmaznak olyan
            információkat, melyekkel a felhasználót azonosítani lehetne. A rendszer összesíti a
            begyűjtött információkat, így azok nem köthetők személyekhez. Ezen cookie-k kizárólag
            a weboldal teljesítményének és így a felhasználói élménynek a javítását szolgálják.
          </p>
          {{components.cookie_table_cookies_performance}}


          <h2>MARKETING COOKIE-K.</h2>

          <p>
            A marketing cookie-k az Önhöz és az Ön érdeklődéséhez jobban köthető hirdetések célba
            juttatását szolgálják. Korlátozni is lehet velük a hirdetések Önnél való megjelenésének
            számát, illetve mérni lehet velük a reklámkampányok hatékonyságát. Emlékeznek arra, hogy
            Ön meglátogatott-e egy adott weboldalt, s ezt megoszthatják más szervezetekkel is, pl.
            hirdetőkkel. A célcsoport-keresés és reklámozás fejlesztését szolgáló cookie-k gyakran
            össze vannak kapcsolva más szervezetek által nyújtott webhely-funkciókkal.
            <br />
            A cookie-kat a funkciójuk és felhasználási céljuk szerint négy kategóriába soroljuk:
            szigorúan szükséges cookie-k, teljesítmény cookie-k, funkcionális cookie-k és
            marketing cookie-k.
          </p>

          {{components.cookie_table_cookies_marketing}}

          <p>
            (●) A "harmadik fél" oszlopban megjelölt cookie-k un. "Third-party" cookie-k, amelyek
            harmadik féltől származnak.
          </p>

          <br />

          <h3>MEGOSZTÁSI ESZKÖZÖK</h3>

          <p>
            A BMW Group weboldalai beágyazott „Share” („Megosztás”) gombokat tartalmaznak, amelyek
            lehetővé teszik a felhasználók számára azt, hogy megosszák a tartalmat barátaikkal az
            olyan közösségi hálózatokon, mint amilyen például a Facebook és a Twitter. Előfordulhat,
            hogy ezek az oldalak sütit telepítenek az Ön eszközére, amikor bejelentkezik rájuk.
            Az ezekről a weboldalakról származó cookie-k telepítésére a BMW Group nincs hatással.
            Kérjük, a harmadik féltől származó cookie-kkal kapcsolatos információkért tekintse meg a
            vonatkozó weboldal cookie-kra vonatkozó részét.
          </p>

          <h2>HARMADIK FÉL COOKIE-KRA VONATKOZÓ ELÉRHETŐSÉGEK:</h2>

          {{components.thirdparty_table}}

          <h3>BÖNGÉSZŐK BEÁLLÍTÁSAIVAL IS SZABÁLYOZHATÓ A COOKIE-K HASZNÁLATA.</h3>
          <p>
            <b>Gyakori böngészők cookie beállításairól az alábbi oldalakon érhető el bővebb infromcáió:</b>
          </p>
          <ul>
            <li><a rel="nofollow noopener" href="https://support.apple.com/hu-hu/guide/safari/sfri11471/11.0/mac/10.13" target="_blank">Apple Safari</a></li>
            <li><a rel="nofollow noopener" href="https://support.google.com/chrome/answer/95647?hl=hu" target="_blank">Google Chrome</a></li>
            <li><a rel="nofollow noopener" href="https://support.microsoft.com/hu-hu/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">Internet Explorer</a></li>
            <li><a rel="nofollow noopener" href="https://support.microsoft.com/en-ie/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy" target="_blank">Microsoft Edge</a></li>
            <li><a rel="nofollow noopener" href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank">Opera</a></li>
            <li><a rel="nofollow noopener" href="https://support.mozilla.org/hu/kb/sutik-engedelyezese-es-tiltasa-amit-weboldak-haszn" target="_blank">Mozilla Firefox</a></li>
          </ul>

          <br />

          <p>
            <b>A cookie-król további információk az Európai Interaktív Digitális Reklámszövetség
            weboldalán érhetők el:</b>
          </p>

          <ul>
            <li><a href="http://www.youronlinechoices.com" target="_blank">www.youronlinechoices.com</a></li>
            <li><a href="http://ec.europa.eu/ipg/basics/legal/cookies/index_en.htm" target="_blank">ec.europa.eu</a></li>
          </ul>

        </article>
      `
    ),
    cat_on: 'Be',
    cat_off: 'Ki',
    btn_accept: 'Elfogadás',
    btn_reject: 'Elutasítás',
    btn_close: '+',
    btn_close_tile: 'Bezárás',
    btn_privacy: 'Adatkezelési tájékoztató',
    btn_cookies: 'Cookie tájékoztató',
    btn_privacy_advanced: 'Adatkezelési tájékoztató',
    btn_save_advanced: 'Beállítások elfogadása',
    btn_advanced: 'Részletes beállítások',
    table_name: 'Cookie név',
    table_cookies: 'Cookie-k',
    table_provider: 'Szolgáltató',
    table_domain: 'Domain',
    table_thirdparty: 'Harmadik fél',
    table_purpose: 'Felhasználás',
    table_source: 'Forrás',
    table_type: 'Típus',
    table_expiry: 'Lejárat',
    table_link: 'Hivatkozás',
    table_description: 'Leírás',
    table_link_opt_out: 'Leiratkozás',
    table_link_cookie_policy: 'Cookie-k',
    table_link_data_policy: 'Adatkezelés',
    table_empty_category: 'Jelenleg nincs ebbe a kategóriába besorolt cookie.',
    cookie_essential_label: 'Szigorúan szükséges cookie-k',
    cookie_essential_desc: 'Ezen cookie-k biztosítják a weboldal megfelelő működését és segítik annak használatát.',
    cookie_essential_detail: (
      `
      <h2>Alkalmazott cookie-k:</h2>
      <ul>
        <li>Munkamenet (session) cookie-k</li>
      </ul>
      `
    ),
    cookie_functional_label: 'Funkcionális cookie-k',
    cookie_functional_desc: 'Ezen cookie-k segítenek megőrizni a látogató preferenciáit, pl. oldalak navigációja között megőrzik az állapotot.',
    cookie_functional_detail: (
      `
      <h2>Alkalmazott cookie-k:</h2>
      <ul>
        <li>Munkamenet (session) cookie-k</li>
        <li>Használatot elősegítő cookie-k</li>
      </ul>
      `
    ),
    cookie_performance_label: 'Teljesítmény cookie-k',
    cookie_performance_desc: 'Ezen cookie-k úgy járulnak hozzá a weboldal továbbfejlesztéséhez, hogy elemzési célú információszerzést tesznek lehetővé.',
    cookie_performance_detail: (
      `
      <h2>Alkalmazott cookie-k:</h2>
      <ul>
        <li>Munkamenet (session) cookie-k</li>
        <li>Használatot elősegítő cookie-k</li>
        <li>Csökkentett funkcionalitású analitika</li>
      </ul>
      `
    ),
    cookie_marketing_label: 'Marketing cookie-k',
    cookie_marketing_desc: 'Ezen cookie-k a weboldal felhasználói szintű viselkedési adatait gyűjtik, hogy releváns ajánlatokat kínálhassunk.',
    cookie_marketing_detail: (
      `
      <h2>Alkalmazott cookie-k:</h2>
      <ul>
        <li>Munkamenet (session) cookie-k</li>
        <li>Használatot elősegítő cookie-k</li>
        <li>Teljes funkcionalitású analitika</li>
        <li>Marketing célú cookie-k</li>
      </ul>
      `
    )
  }
};
