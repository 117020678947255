import util from './util';
import i18n_locales from './i18n';
import defaultSettings from './defaults';
import TemplateParser from './tplparser';
import Component from './component';
import Cookies from './cookies';
import EventEmitter from './EventEmitter';

const konsole = util.konsole;

const DEVMODE = window.location.hostname.search(/\.local$|^localhost/i) > -1;



class DwCookieConsent {

  constructor(settings) {
    const hostname = window.location.hostname;

    if (this.initialized || !(this.initialized = true)) { return; }
    this.sharedData = {};
    this.util = util;
    this._emitter = new EventEmitter('DwCookieConsent');
    this.elWrapper = document.createElement('div');
    this.elFragment.appendChild(this.elWrapper);
    this.settings = util.mergeDeep({}, defaultSettings, (settings || {}));
    this.cookies = new Cookies();
    this.visible = false;
    this.rendered = false;
    this.components = {};
    this.actions = this.settings.actions || {};
    this.placement = this.settings.placement || 'topcover';
    this.statusCookieDomain = this.settings.cookieDomain || ('.' + hostname);

    if (hostname.search(/^localhost|\d+\.\d+\.\d+\.\d+/) > -1) {
      this.statusCookieDomain = '';
    }
    this.statusCookieExpire = (typeof this.settings.cookieExpire === 'number'
      ? this.settings.cookieExpire : 365
    );

    this.statusCookiePath = this.settings.cookiePath || '/';

    this.scriptProps = [ // to clone
      'innerText', 'text', 'class', 'id', 'name', 'defer', 'async', 'src'
    ];

    this.wrapperClasses = [
      'dwcc-cookieconsent',
      'dwcc-placement-' + this.placement,
      'dwcc-theme-' + (this.settings.theme || 'silver')
    ];

    this.savedState = this.getStatusCookie();
    this.savedCategory = this.getCategoryByType(this.savedState);
    // consentMode === op-in
    // initial category csak a slide alapállapota
    // nem kerül beállításra
    this.initialCategory = (
      this.getCategoryByType(this.settings.initialCategory) ||
      this.settings.categories[0]
    );

    if (this.savedState !== null && !this.savedCategory) {
      this.savedState = null;
      // remove invalid cookie
      this.removeStatusCookie();
    }

    this.i18n = this.locales[this.settings.lang || 'en'] || this.locales['en'];

    this.templateData = {
      i18n: this.i18n,
      components: {}
    };

    this.renderer = this.parser.createRenderer({
      template: this.settings.template,
      data: this.templateData,
      id: 'DwCookieConsent.renderer',
      context: this
    });

    this.documentLoaded = false;

    this.initComponents();

    if (document.readyState === "interactive" || document.readyState === "complete") {
      this._docReady(settings);
    }
    else {
      document.addEventListener('DOMContentLoaded', e => this._docReady(settings));
    }
  };

  _docReady(settings) {
    if (this.documentLoaded) { return; }
    this.documentLoaded = true;

    if (this.savedState || this.settings.consentMode === 'opt-out') {
      this.applyConsentByCategory(this.savedCategory || this.initialCategory);
    }

    if (this.settings.autoload !== false) {
      this.showIfNotConsent('autoload');
    }

    if (this.settings.editConsentSelector) {
      const moreAttr = 'data-dwcc-mode';

      util.el_liveBind(document.body, 'click', this.settings.editConsentSelector, (e, elem) => {
        const mode = (elem && elem.getAttribute(moreAttr)) || (
          e && e.target && e.target.getAttribute(moreAttr)
        );
        this.show();
        this.emit('onOpen', mode);
      });
    }

    this.emit('onReady');
  };

  initComponents() {
    var compSet = this.settings.components || {};

    for (var cid in compSet) {
      if (compSet.hasOwnProperty(cid)) {
        if (this.components[cid] instanceof Component) {
          this.components[cid].destroy();
        }
        var component = new Component(cid, compSet[cid], this);
        this.components[component.instanceId] = component;
        this.templateData.components[cid] = component.getComponentHolder();
      }
    }
  }

  getStatus() {
    return this.savedState;
  }

  getStatusCookie() {
    let c = this.cookies.get(this.settings.decisionCookieName);
    try { c = JSON.parse(c); }
    catch (err) { c = null; }

    const reval_ts = (
      typeof this.settings.revalidationFrom === 'number' && this.settings.revalidationFrom
    );

    if (c !== null && typeof c === 'object') {
      if (c.state && typeof c.ts === 'number' && (!reval_ts || reval_ts < c.ts)) {
        return c.state;
      }
      else {
        this.removeStatusCookie();
        void (DEVMODE && konsole.log(
          'Invalid consent status cookie has been removed.', c)
        );
      }
    }
    return null;
  }

  setStatusCookie(state) {
    const options = {
      domain: this.statusCookieDomain,
      path: this.statusCookiePath,
      expire: this.statusCookieExpire
    };

    const value = JSON.stringify({
      state: state,
      ts: (new Date()) - 0
    });

    this.cookies.set(this.settings.decisionCookieName, value, options);

    const saved = this.getStatusCookie();

    if (saved === null) {
      konsole.error(
        `The status cookie cannot be set. Maybe the "cookieDomain"
        you set does not match the current site's domain.`,
        this.statusCookieDomain
      );
    }
    else {
      this.savedState = saved;
    }

    return saved;
  }

  removeStatusCookie() {
    return this.cookies.remove(
      this.settings.decisionCookieName, this.statusCookieDomain, this.statusCookiePath
    );
  }

  removeCookie(pattern, domain, path) {
    if (Array.isArray(pattern)) {
      return pattern.forEach(p => this.removeCookie(p));
    }
    if (typeof pattern !== "string" && pattern instanceof RegExp !== true) {
      void (DEVMODE && konsole.error('Invalid pattern', pattern));
      return;
    }
    if (typeof path !== 'string') {
      path = typeof domain !== 'string' ? this.statusCookiePath : '/';
    }
    if (typeof domain !== 'string') {
      domain = this.statusCookieDomain;
    }

    Object.keys(this.cookies.getAll()).forEach(cookieName => {
      if (
        (pattern instanceof RegExp && pattern.saved(cookieName)) ||
        (typeof pattern === "string" && cookieName === pattern)
      ) {
        void (DEVMODE && konsole.log('cookie found', cookieName));
        this.cookies.remove(cookieName, domain, '/');
      }
      else {
        // void (DEVMODE && konsole.log('not found', cookieName));
      }
    });
  }

  removeCookies(patterns, domain, whiteList) {
    if (!Array.isArray(patterns)) { patterns = [patterns]; }
    patterns.forEach(p => {
      if (!whiteList || (whiteList.indexOf && whiteList.indexOf(p) === -1)) {
        this.removeCookie(p, domain);
      }
    });
  }

  getCategoryByType(type) {
    const category = this.settings.categories.find(c => c.type === type);
    return category || null;
  }

  accept(value, keepOpen) {
    const category = this.getCategoryByType(value);
    const prevState = this.savedState;
    let doReload = false;

    if (!category) {
      throw new Error('unexpected error during validation of arg0.');
    }
    const savedState = this.setStatusCookie(value);

    if (savedState !== null) {
      this.applyConsentByCategory(category);
      if (!keepOpen) {
        this.hide();
      }
      if (prevState !== category.type && prevState !== null) {
        doReload = true;
      }

      this.emit('onAccepted', category, doReload);

      if (doReload) {
        window.location.reload();
      }
    }
    else {
      window.alert(this.settings.errorCookiesNotCreated);
    }
  }

  reject(keepOpen) {
    this.removeStatusCookie();
    this.purgeNonConsentCookies(false);
    if (!keepOpen) {
      this.hide();
      window.location.reload();
    }
  }

  enableScriptTag(el, ctype) {
    const el_parent = el.parentElement;
    let src = el.getAttribute('data-src');
    let type = el.getAttribute('data-type') || "text/javascript";
    const newEl = document.createElement('script');

    for (let prop in el.dataset) {
      if (el.dataset.hasOwnProperty(prop)) {
        newEl.dataset[prop] = el.dataset[prop];
      }
    }

    for (let i = 0; i < this.scriptProps.length; i++) {
      let prop = this.scriptProps[i];
      if (prop === 'innerText' || prop === 'text') {
        newEl[prop] = el[prop] + (DEVMODE
          ? `\n\n window.console && console.log("script type [${ctype}] initalized.")`
          : ''
        );
      }
      else if (typeof el[prop] !== "undefined" && el[prop]) {
        newEl[prop] = el[prop];
      }
    }
    if (type) newEl.type = type;
    if (src) newEl.src = src;

    el_parent.insertBefore(newEl, el);
    el_parent.removeChild(el);
  }

  purgeNonConsentCookies(category) {
    const categories = this.settings.categories;
    const currentI = category === false ? -1 : categories.indexOf(category);
    if (currentI === categories.length - 1) {
      void (DEVMODE && konsole.log(' There is no need to purge cookies (top level).'));
      return;
    }

    const whiteList = category && Array.prototype.concat.apply([], category.cookies.map(
      c => !Array.isArray(c.pattern) ? [c.pattern || ''] : c.pattern || ''
    ));

    for (let i = currentI + 1; i < categories.length; i++) {
      // only form higher level categories
      if (typeof categories[i].onOptOut === 'function') {
        categories[i].onOptOut(this);
      }
      if (categories[i].cookies.length) {
        (categories[i].cookies || []).forEach(
          c => this.removeCookies(c.pattern, c.domain, whiteList)
        );
      }
    }
  }

  applyConsentByCategory(category) {
    if (!category) { return this.reject(); }

    if (this.savedCategory !== category) {
      this.purgeNonConsentCookies(category);
    }

    this.savedCategory = category;

    void (DEVMODE && konsole.log('Apply consent level:', category.type));

    if (typeof category.onOptIn === 'function') { category.onOptIn(this); }

    var scriptTags = (
      Array.isArray(category.scriptTags) ? category.scriptTags : [category.scriptTags]
    );
    for (let i = 0; i < scriptTags.length; i++) {
      document.querySelectorAll(`script[type*="${scriptTags[i]}"]`)
        .forEach(el => this.enableScriptTag(el, category.type))
      ;
    }
  }

  getComponent(value, _prop, _all) {
    _prop = _prop ? _prop : 'cid';
    _all = _all ? [] : false;

    for (var instanceId in this.components) {
      if (
        this.components.hasOwnProperty(instanceId) &&
        this.components[instanceId][_prop] === value) {
        if (_all === false) {
          return this.components[instanceId];
        }
        _all.push(this.components[instanceId]);
      }
    }

    return _all;
  }

  exec(where, method, args) {
    if (!this[where] || typeof this[where] !== "object") { return; }
    for (var prop in this[where]) {
      if (typeof this[where][prop][method] === 'function') {
        this[where][prop][method].apply(this[where][prop], args);
      }
    }
  }

  render(_obligate) {
    if ((!_obligate && this.rendered) || !(this.rendered = true)) { return; }
    var className = this.wrapperClasses.join(' ');

    // console.time('renderTime');
    this.exec('components', 'detach');
    this.elWrapper.className = className;
    this.elWrapper.innerHTML = this.renderer();

    this.attachComponenets(this.elWrapper);
    // console.timeEnd('renderTime');
  }

  attachComponenets(parentNode) {
    if (!parentNode || parentNode.nodeType !== 1) {
      konsole.log('Err: arg0 is not an element node of DOM', parentNode);
      return;
    }
    var nodes = Array.prototype.slice.call(
      parentNode.getElementsByClassName(this.classNameHolder)
    );
    for (var i = 0, instanceId; i < nodes.length; i++) {
      instanceId = nodes[i].getAttribute('data-instance-id');
      if (this.components[instanceId] instanceof Component) {
        this.components[instanceId].attach(nodes[i]);
      }
    }
  }

  resize() {
    this.exec('components', 'resize');
  }

  _initSoftOptIn() {
    if (this.settings.consentMode !== 'soft-opt-in') {
      return;
    }
    if (typeof this.settings.detectSoftOptIn === 'function') {
      this.settings.detectSoftOptIn.call(this, value => {
        if (value || this.settings.softOptInCategory) {
          this.accept(value || this.settings.softOptInCategory);
        }
      });
    }
  }

  showIfNotConsent(reason) {
    if (this.settings.autoload && reason !== 'autoload') {
      util.konsole.error(
        'Cannot call showIfNotConsent method if setting.autoload is true.'
      );
      return;
    }

    if (!this.savedState) {
      this.show();
      if (this.settings.consentMode === 'soft-opt-in') {
        this._initSoftOptIn();
      }

      this.emit('onNonConsentShow');
    }
  }

  show() {
    var that = this;
    if (that.visible || !(that.visible = true)) { return; }
    document.body.appendChild(this.elWrapper);
    that.render();

    this.emit('onShow');
  }

  hide() {
    if (!this.visible || (this.visible = false)) { return; }
    if (this.elWrapper.parentNode) {
      this.elWrapper.parentNode.removeChild(this.elWrapper);
    }

    this.emit('onHide');
  }

  scrollTop(scrollDuration) {
    const scrollHeight = window.scrollY;
    const scrollStep = Math.PI / (scrollDuration / 15);
    const cosParameter = scrollHeight / 2;
    let scrollCount = 0;
    let scrollMargin;
    let timerID = setInterval(() => {
      if (window.scrollY !== 0) {
        scrollCount = scrollCount + 1;
        scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
        window.scrollTo(0, scrollHeight - scrollMargin);
      }
      else {
        clearInterval(timerID);
      }
    }, 15);
  }

  on(eventName, listener, data) {
    this._emitter.on(eventName, listener, data, this);
  }

  once(eventName, listener, data) {
    this._emitter.on(eventName, listener, data, this, true);
  }

  off(eventName, listener) {
    this._emitter.off(eventName, listener);
  }

  emit(eventName, ...args) {
    if (typeof this.settings[eventName] === 'function') {
      this.settings[eventName].apply(this, args);

      this._emitter.emit(eventName, ...args);
    }
  }
};


DwCookieConsent.prototype.parser = new TemplateParser();
DwCookieConsent.prototype.elFragment = document.createDocumentFragment();
DwCookieConsent.prototype.classNameHolder = 'dwcc-cmp-holder';
DwCookieConsent.prototype.TemplateParser = TemplateParser;
DwCookieConsent.prototype.Component = Component;
DwCookieConsent.prototype.locales = i18n_locales;
DwCookieConsent.prototype.util = DwCookieConsent.util = util;


export default DwCookieConsent;
