/*
  Simple EventEmitter
  @author: Imre Ardelean blatart@gmail.com
*/

export default (function() {

  class EventObject {
    constructor(eventName, listener, data, context, once, __emiter) {
      this.__emiter = __emiter;
      this.once = !!once;
      this.eventName = eventName;
      this.listener = listener;
      this.context = context;
      this.data = data || null;
    }

    emit(...args) {
      if (this.listener) {
        this.listener.call(this.context || this, this.eventName, this.data, ...args);
        if (this.once) { this.__emiter.off(this.eventName, this.listener); }
      }
    }

    destroy() {
      for (const prop in this) {
        if (this.hasOwnProperty(prop)) { delete this[prop]; }
      }
    }
  }

  class EventEmitter {

    constructor(name) {
      this.name = name || null;
      this.events = {};
    }

    on(eventName, listener, data, context, once) {
      if (typeof listener !== 'function') { return; }

      if (!Array.isArray(this.events[eventName])) {
        this.events[eventName] = [];
      }
      this.events[eventName].push(
        new EventObject(eventName, listener, data, context, !!once, this)
      );
      return this;
    }

    off(eventName, listener) {
      if (typeof eventName === 'function') {
        listener = eventName;
        for (eventName in this.events) {
          if (this.events.hasOwnProperty(eventName)) {
            this.off(eventName, listener);
          }
        }
        return;
      }

      if (typeof listener !== 'function') { return; }

      if (typeof this.events[eventName] === 'object') {
        for (var i = this.events[eventName].length - 1; i >= 0; i--) {
          if (this.events[eventName][i].listener === listener) {
            this.events[eventName][i].destroy();
            this.events[eventName].splice(i, 1);
          }
        }
      }
      return this;
    }

    emit(eventName, ...args) {
      if (typeof this.events[eventName] === 'object') {
        for (let i = 0; i < this.events[eventName].length; i++) {
          this.events[eventName][i].emit(...args);
        }
      }
      return this;
    }

    once(eventName, listener, data, contex) {
      return this.on(eventName, listener, data, contex, true);
    }
  };

  return EventEmitter;
})();
