import {konsole, el_liveBind, el_trigger, mergeDeep} from './util';
import TemplateParser from './tplparser';

export default class Component {

  constructor(cid, data, dwCookieConsent) {

    var compTypeSets = dwCookieConsent.settings.componentTypes || {};

    this.componentType = compTypeSets[data.type] || {};
    this.cid = cid;
    this.instanceId = 'dwcc_ciid_' + this._incrId.get();
    this.dwCookieConsent = dwCookieConsent;
    this.state = {};
    this.causes = {
      hide: null,
      show: null
    };

    this.componentData = Object.assign(
      {cid: cid, state: this.state, visible: true, inline: false },
      this.componentType.defaults || {}, data
    );

    if (typeof this.componentData.init === 'function') {
      this.componentData.init(this, dwCookieConsent);
    }
    this._errorPre = (
      `This componentType ${data.type} of ${cid || 'N/A'} component `
    );

    if (!this.componentData.type || !this.componentType) {
      throw new Error(this._errorPre + 'does not exist.');
    }

    if (typeof this.componentData.visible === "function") {
      this.visible = !!this.componentData.visible(this);
    }
    else {
      this.visible = !!this.componentData.visible;
    }

    this.elComponent = document.createElement(
      this.componentData.inline ? 'span' : 'div'
    );

    this.elComponent.className = [
      this.componentData.extraClass || '',
      "dwcc-component",
      "dwcc-type-" + this.componentData.type,
      "dwcc-component-" + this.cid
    ].join(' ').trim();

    this.elComponent.setAttribute('id', "dwcc-instance-id-" + this.instanceId);

    this.templateData = Object.assign({
      component: this.componentData
    }, this.dwCookieConsent.templateData);

    this.events = mergeDeep(
      {}, this.componentType.events || {}, this.componentData.events || {}
    );
    this.applyEvents(this.events);

    this.renderer = this.parser.createRenderer({
      template: this.componentType.template,
      data: this.templateData,
      id: 'Component.renderer',
      context: this
    });

    for (var prop in this.componentType) {
      this[prop] = this.componentType[prop];
    }

    if (typeof this.componentType.extend === "function") {
      this.componentType.extend.call(this, this.componentData);
    }
    if (this.visible === false) { this.hide(); }
  }

  applyEvents(events, _selector) {
    if (typeof events !== "object") { return; }

    _selector = typeof _selector === "string" && _selector ? _selector : null;

    for (var prop in events) {
      if (!events.hasOwnProperty(prop)) { continue; }

      if (typeof events[prop] === 'function') {
        let element = this.elComponent;

        if (_selector === '{document}') { element = document; }
        if (_selector === '{window}') { element = window; }
        if (_selector.charAt(0) === '{') { _selector = false; }

        el_liveBind(element, prop, _selector, (function(fn, that) {
          return function(e) {
            var args = Array.prototype.slice.call(arguments, 1);
            args.unshift(e, this, that.componentData);
            fn.apply(that, args);
          };
        })(events[prop], this));
      }
      else if (typeof events[prop] === 'object' && events[prop]) {
        this.applyEvents(events[prop], prop);
      }
    }
  }

  getComponentHolder() {
    return (
      '<span' +
        ' data-cid="' + this.cid + '"' +
        ' data-instance-id="' + this.instanceId + '"' +
        ' id="' + this.instanceId + '-holder"' +
        ' class="' + this.dwCookieConsent.classNameHolder + '"' +
      '></span>'
    );
  }

  render(_obligate) {
    if ((!_obligate && this.rendered) || !(this.rendered = true)) { return; }
    this.elComponent.innerHTML = this.renderer();
    el_trigger(this.elComponent, 'dwcc-rendered');
  }

  renderContentTo(el) {
    this.render();
    // todo, replace holder divs
    el.innerHTML = this.elComponent.innerHTML;
  }

  detach(_obligate) {
    if (!this.attached && _obligate !== true) { return; }
    el_trigger(this.elComponent, 'dwcc-detached');
    this.attached = false;
  }

  attach(_elHolder, _obligate) {
    if (this.attached && _obligate !== true) { return; }
    if (typeof _elHolder === 'undefined') {
      _elHolder = document.getElementById(this.instanceId + '-holder');
    }
    else if (!_elHolder || _elHolder.nodeType !== 1) {
      konsole.log('Err: arg1 is not an element node', _elHolder);
      return;
    }

    if (_elHolder && _elHolder.nodeType === 1) {
      this.attached = true;

      if (_elHolder.parentNode) {
        _elHolder.parentNode.insertBefore(this.elComponent, _elHolder);
        _elHolder.parentNode.removeChild(_elHolder);
      }
      else {
        konsole.log('Err: arg0.parentNode does not exist.', _elHolder);
      }

      this.render();
      this.dwCookieConsent.attachComponenets(this.elComponent);
      el_trigger(this.elComponent, 'dwcc-attached');
      if (typeof this.componentData.onAttached === "function") {
        this.componentData.onAttached(this);
      }
    }
  }

  callAction(actionId) {
    actionId = actionId || this.componentData.action;

    if (!actionId) {
      konsole.log(this.cid + ': invalid action id [' + actionId + ']');
    }
    if (typeof actionId === "function") {
      actionId.call(this.componentData, this);
    }
    else if (typeof this.dwCookieConsent.actions[actionId] === 'function') {
      this.dwCookieConsent.actions[actionId].call(this.componentData, this);
    }
    else {
      konsole.log(`settings.action. ${actionId}() does not exist.`);
    }
  }

  resize() {

  }

  show(cause) {
    this.causes.show = cause || null;
    this.elComponent.classList.toggle('hidden', false);
    this.visible = true;
    this.dwCookieConsent.resize();
    if (typeof this.componentData.onShow === "function") {
      this.componentData.onShow(this, cause);
    }
  }

  hide(cause) {
    this.causes.hide = cause || null;
    this.elComponent.classList.toggle('hidden', true);
    this.visible = false;
    this.dwCookieConsent.resize();
    if (typeof this.componentData.onHide === "function") {
      this.componentData.onHide(this, cause);
    }
  }

  destroy() {
    // TODO: implement this
  }
};

Component.prototype.parser = new TemplateParser();
Component.prototype._incrId = { i: 0, get: function() { return this.i++; }};
