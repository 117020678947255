const noop = function() {};
const konsole = window.console && console.log ? console : {
  log: noop, dir: noop, error: noop
};
const isObject = function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item) && item !== null);
};

const mergeDeep = function mergeDeep(target /*, many, of, args */) {
  var sources = Array.prototype.slice.call(arguments, 1);
  for (var s = 0; s < sources.length; s++) {
    var source = sources[s];
    if (isObject(target) && isObject(source)) {
      var keys = Object.keys(source), i = 0, key;
      for (; i < keys.length; i++) {
        key = keys[i];
        if (isObject(source[key])) {
          if (!target[key]) { target[key] = {}; }
          mergeDeep(target[key], source[key]);
        }
        else {
          target[key] = source[key];
        }
      }
    }
  }
  return target;
};


const el_liveBind = function el_liveBind(element, event, descendentSelector, callback, evtOpts) {
  var eventNames = event.trim().split(/\s+/);
  evtOpts = typeof evtOpts !== "undefined" ? evtOpts : {passive: false};

  function listener(e) {
    var elem = descendentSelector ? e.target.closest(descendentSelector) : element;
    if (elem && typeof callback === 'function') {
      callback.call(elem, e, elem, unbind);
    }
  }

  function unbind(e) {
    for (var i = 0; i < eventNames.length; i++) {
      element.removeEventListener(eventNames[i], listener);
    }
  };

  for (var i = 0; i < eventNames.length; i++) {
    element.addEventListener(eventNames[i], listener, evtOpts);
  }

  return unbind;
};


const el_trigger = function el_trigger(el, event, data, bubbles = true, cancelable = false) {
  var eventNames = event.trim().split(/\s+/);
  if (!el || !el.dispatchEvent) {
    konsole.error('Invalid arg0 "el"', el);
    return;
  }
  for (var i = 0; i < eventNames.length; i++) {
    el.dispatchEvent(
      new window.CustomEvent(eventNames[i], {
        bubbles: !!bubbles,
        cancelable: !!cancelable,
        detail: { customEvent: true, customData: data || null }
      })
    );
  }
};


const el_getHeight = function el_getHeight(el) {
  var styles = window.getComputedStyle(el);
  var height = el.offsetHeight;
  var borderTopW = parseFloat(styles.borderTopWidth);
  var borderBottomW = parseFloat(styles.borderBottomWidth);
  var paddingTop = parseFloat(styles.paddingTop);
  var paddingBottom = parseFloat(styles.paddingBottom);
  return height - borderBottomW - borderTopW - paddingTop - paddingBottom;
};


const util = {
  noop, konsole, isObject, mergeDeep, el_liveBind, el_trigger, el_getHeight
};


export {
  noop, konsole, isObject, mergeDeep, el_liveBind, el_trigger, el_getHeight
};

export default util;
