
export default {
  lang: 'hu',
  theme: 'bmw-motorrad',

  consentMode: 'opt-in', // opt-in || opt-out || soft-opt-in
  softOptInCategory: 'marketing',
  initialCategory: 'marketing',
  revalidationFrom: 1550769460127,
  decisionCookieName: 'dwcconsent',
  decisionCookieMaxAge: 365 * 24 * 60 * 60,
  decisionCookieDomain: null,
  placement: 'bottomcover', // topcover, bottomcover
  editConsentSelector: '.show-dwcc-cookie-settings',
  rejectConsentSelector: '.dwcc-reject',
  errorCookiesNotCreated: 'Unable to save your decision on the use of cookies. Your browser does not allow cookies to be created.',

  cookieTableMap: {
    pattern: '{{i18n.table_name}}',
    purpose: '{{i18n.table_purpose}}',
    expiry: '{{i18n.table_expiry}}',
    source: '{{i18n.table_source}}',
    third_party: '{{i18n.table_thirdparty}}',
    domain: '{{i18n.table_domain}}',
    type: '{{i18n.table_type}}'
  },

  policyGroupTableMap: {
    provider: '{{i18n.table_provider}}',
    policy_group: '{{i18n.table_cookies}}',
    description: false,
    links: {
      content: '{{i18n.table_link}}',
      data_policy: '{{i18n.table_link_data_policy}}',
      cookie_policy: '{{i18n.table_link_cookie_policy}}',
      opt_out: '{{i18n.table_link_opt_out}}'
    }
  },

  policyGroups: [
    {
      policy_group: 'google',
      provider: `Google <small>Google Inc.,1600 Amphitheatre Parkway Mountain View, CA 94043, USA</small>:
        (Doubleclick Bid Manager,
        DoubleClick Floodlight, Google Adwords, Google Dynamic Remarketing,
        Google Conversion Tag, Google Analytics)
      `,
      links: {
        data_policy: 'https://policies.google.com/technologies/partner-sites?hl=en',
        opt_out: 'http://www.youronlinechoices.com/hu/sugo',
        cookie_policy: [
          'https://support.google.com/ads/answer/2662922?hl=hu',
          'https://policies.google.com/technologies/cookies?hl=hu'
        ]
      }
    },
    {
      policy_group: 'facebook',
      provider: `Facebook <small>Facebook Inc., 1 Hacker Way, Menlo Park, California 94025, USA</small> (Facebook Pixel)`,
      links: {
        data_policy: 'https://www.facebook.com/about/privacy/update',
        opt_out: 'http://www.youronlinechoices.com/hu/sugo',
        cookie_policy: 'https://www.facebook.com/policies/cookies/'
      }
    },
    {
      policy_group: 'adobe',
      provider: `Adobe Experience Cloud <small>Adobe Inc.: 345 Park Avenue, San Jose, California 95110-2704, USA</small>`,
      links: {
        data_policy: 'https://www.adobe.com/hu/privacy/marketing-cloud.html',
        opt_out: 'https://www.adobe.com/hu/privacy/opt-out.html',
        cookie_policy: 'https://marketing.adobe.com/resources/help/en_US/whitepapers/cookies/cookies_mc.html'
      }
    },
    {
      policy_group: 'ignitionone',
      provider: `Ignition One`,
      links: {
        data_policy: 'https://www.ignitionone.com/privacy-policy/',
        opt_out: 'https://www.ignitionone.com/choice-mechanisms-opt-out/',
        cookie_policy: 'https://www.ignitionone.com/cookies-list/'
      }
    },

    {
      policy_group: 'tradedesk',
      provider: `Trade Desk`,
      links: {
        data_policy: 'https://www.thetradedesk.com/general/privacy',
        opt_out: 'https://www.adsrvr.org/opt-out.html'
      }
    },
    {
      policy_group: 'casalemedia',
      provider: `Casalemedia`,
      links: {
        data_policy: 'http://casalemedia.com/',
        opt_out: 'http://casalemedia.com/'
      }
    },
    {
      policy_group: 'rubicon',
      provider: `Rubicon Project`,
      links: {
        data_policy: 'https://rubiconproject.com/rubicon-project-yield-optimization-privacy-policy/',
        opt_out: 'https://rubiconproject.com/privacy/consumer-online-profile-and-opt-out/',
        cookie_policy: 'https://rubiconproject.com/rubicon-project-yield-optimization-privacy-policy/platform-cookie-statement/'
      }
    },
    {
      policy_group: 'usabilla',
      provider: `Usabilla`,
      links: {
        data_policy: 'https://usabilla.com/gdpr/',
        opt_out: 'http://www.youronlinechoices.com/hu/sugo'
      }
    },
    {
      policy_group: 'adform',
      provider: `Adform`,
      links: {
        data_policy: 'https://site.adform.com/privacy-center/platform-privacy/product-and-services-privacy-policy/',
        opt_out: 'https://site.adform.com/privacy-center/platform-privacy/opt-out/'
      }
    },
    {
      policy_group: 'cadreon',
      provider: `Cadreon`,
      links: {
        data_policy: 'http://cadreon.hu/privacy-notice/',
        opt_out: 'http://www.youronlinechoices.com/hu/sugo'
      }
    }
  ],
  categories: [
    {
      type: 'essential',
      label: '{{i18n.cookie_essential_label}}',
      desc: '{{i18n.cookie_essential_desc}}',
      detail: '{{i18n.cookie_essential_detail}}',
      scriptTags: ['opt-in-essential'],

      cookies: [

        {
          pattern: ['dwcconsent'],
          purpose: {
            i18n: {
              hu: 'A cookie beállításra kerül amikor a felhasználó elfogadja a cookie-k használatát.'
            }
          },
          domain: null,
          third_party: false,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: '365 nap'
            }
          },
          source: null,
          policy_group: null
        },
        {
          pattern: ['PHPSESSID'],
          purpose: {
            i18n: {
              hu: 'Eltárolja a munkamenet azonosítót.'
            }
          },
          domain: null,
          third_party: false,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: 'munkamenet'
            }
          },
          source: null,
          policy_group: null
        }
      ]
    },
    {
      type: 'functional',
      label: '{{i18n.cookie_functional_label}}',
      desc: '{{i18n.cookie_functional_desc}}',
      detail: '{{i18n.cookie_functional_detail}}',
      scriptTags: ['opt-in-functional'],
      cookies: [
        {
          pattern: 'SIDCC',
          purpose: {
            i18n: {
              hu: 'Egy biztonsági cookie mely védi a felhasználó adatait a jogosulatlan hozzáféréstől.'
            }
          },
          domain: ['.google.com', '.youtube.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: '3 hónap'
            }
          },
          source: null,
          policy_group: 'google'
        }
      ]
    },
    {
      type: 'performance',
      label: '{{i18n.cookie_performance_label}}',
      desc: '{{i18n.cookie_performance_desc}}',
      detail: '{{i18n.cookie_performance_detail}}',
      scriptTags: ['opt-in-performance'],
      cookies: [
        {
          pattern: ['HSID', 'LOGIN_INFO', 'SAPISID', 'SID', 'SSID', 'APISID'],
          purpose: {
            i18n: {
              hu: 'Ez a cookie beágyazott videók által kerül beállításra. Névtelen statisztikai adatok regisztrálásában vesz rész. Például, hogy hány alkalommal jelent meg egy videó és hogy milyen beállításokkal kerül lejátszásra. Szenzitív adat nem kerül összegyűjtésre mindaddig, míg be nem jelentkezik Google fiókjába, ebben az esetben az Ön választása összekapcsolásra kerül fiókjával, például, amikor rákattint a "Tetszik" gombra egy videó alatt.'
            }
          },
          domain: ['.google.com', '.youtube.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: '2 év'
            }
          },
          source: 'Google',
          policy_group: 'google'
        },

        {
          pattern: ['_gid', '_gat'],
          purpose: {
            i18n: {
              hu: 'Ez a cookie a google.com adatvédelmi nyilatkozata szerint gyűjti a weboldal statisztikáit és méri a konverziókat.'
            }
          },
          domain: ['.google.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: '1 hónap'
            }
          },
          source: 'Google',
          policy_group: 'google'
        },
        {
          pattern: ['_ga'],
          purpose: {
            i18n: {
              hu: 'Ez a cookie a google.com adatvédelmi nyilatkozata szerint gyűjti a weboldal statisztikáit.'
            }
          },
          domain: ['.google.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: '2 év'
            }
          },
          source: 'Google',
          policy_group: 'google'
        },
        {
          pattern: 'CONSENT',
          purpose: {
            i18n: {
              hu: 'Ez a cookie beágyazott videók által kerül beállításra. Névtelen statisztikai adatok regisztrálásában vesz rész. Például, hogy hány alkalommal jelent meg egy videó és hogy milyen beállításokkal kerül lejátszásra. Szenzitív adat nem kerül összegyűjtésre mindaddig, míg be nem jelentkezik Google fiókjába, ebben az esetben az Ön választása összekapcsolásra kerül fiókjával, például, amikor rákattint a "Tetszik" gombra egy videó alatt.'
            }
          },
          domain: ['.google.com', '.youtube.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: 'állandó'
            }
          },
          source: 'Google',
          policy_group: 'google'
        }
      ]
    },
    {
      type: 'marketing',
      label: '{{i18n.cookie_marketing_label}}',
      desc: '{{i18n.cookie_marketing_desc}}',
      detail: '{{i18n.cookie_marketing_detail}}',
      scriptTags: ['opt-in-marketing'],
      onOptIn() {
        // console.log('opt-in-by settings');
      },
      onOptOut() {
        // console.log('opt-out-by settings');
      },
      cookies: [
        {
          pattern: ['1P_JAR', '_gid', '_gat'],
          purpose: {
            i18n: {
              hu: 'Ez a cookie a google.com adatvédelmi nyilatkozata szerint gyűjti a weboldal statisztikáit és méri a konverziókat.'
            }
          },
          domain: ['.google.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: '1 hónap'
            }
          },
          source: 'Google',
          policy_group: 'google'
        },

        {
          pattern: ['_ga'],
          purpose: {
            i18n: {
              hu: 'Ez a cookie a google.com adatvédelmi nyilatkozata szerint gyűjti a weboldal statisztikáit és méri a konverziókat.'
            }
          },
          domain: ['.google.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: '2 év'
            }
          },
          source: 'Google',
          policy_group: 'google'
        },

        {
          pattern: 'ANID',
          purpose: {
            i18n: {
              hu: 'A Google használja ezt a cookie-t, hirdetési szolgáltatásaihoz, amelyet a böngésző a google.com domain alatt tárol.',
              de: 'Dies sind Cookies, die Google AdWords verwendet, um die Leistung der Werbung zu überwachen und Werbung für Besucher auf der Grundlage der zuvor besuchten Websites zu schalten.'
            }
          },
          domain: ['.google.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: '1 év'
            }
          },
          source: 'Google',
          policy_group: 'google'
        },


        {
          pattern: 'NID',
          purpose: {
            i18n: {
              hu: 'Regisztrál egy egyedi azonosítót, amely azonosítja a visszatérő felhasználó eszközét. Az azonosítót a célzott hirdetésekhez használják.'
            }
          },
          domain: ['.google.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: '6 hónap'
            }
          },
          source: 'Google',
          policy_group: 'google'
        },


        {
          pattern: 'OGPC',
          purpose: {
            i18n: {
              hu: 'Ezt a cookie-t a Google Maps szolgáltatás használja, hogy felhasználói preferenciákat és információkat tároljon.'
            }
          },
          domain: ['.google.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: '13 hónap'
            }
          },
          source: 'Google',
          policy_group: 'google'
        },


        {
          pattern: 'PREF',
          purpose: {
            i18n: {
              hu: 'Regisztrál egy egyedi azonosítót a Google számára, hogy statisztikát tároljon, hogyan használják a Youtube videókat különböző oldalakon.'
            }
          },
          domain: ['.google.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: '2 év'
            }
          },
          source: 'Google',
          policy_group: 'google'
        },


        {
          pattern: 'S',
          purpose: {
            i18n: {
              hu: 'Az „S” cookie bizonyos szolgáltatásokat gyűjthet, amelyek segítenek a szolgáltatások javításában, beleértve a leggyakrabban látogatott oldalakat és azt, hogy a felhasználók bizonyos oldalakon hibaüzeneteket kapnak. Ez a cookie a PPC (pay per click) és a affiliate reklám hatékonyságának anonimikus mérésére is használható.'
            }
          },
          domain: ['.google.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: 'munkamenet'
            }
          },
          source: 'Google',
          policy_group: 'google'
        },


        {
          pattern: 'VISITOR_INFO1_LIVE',
          purpose: {
            i18n: {
              hu: 'Megkísérli megbecsülni a látogató sávszélességét azokon az oldalakon ahol BMW IT / NTT videók vannak integrálva.'
            }
          },
          domain: ['.google.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: '2 év'
            }
          },
          source: 'Google',
          policy_group: 'google'
        },


        {
          pattern: 'YSC',
          purpose: {
            i18n: {
              hu: 'Regisztrál egy egyedi azonosítót, hogy statisztikát tároljon azokról a Youtube videókról amit a felhasználó megtekintett.'
            }
          },
          domain: ['.google.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: 'munkamenet'
            }
          },
          source: 'Google',
          policy_group: 'google'
        },


        {
          pattern: 'OTZ',
          purpose: {
            i18n: {
              hu: 'Az „OTZ” a Google Analytics által használt cookie, amely a webhelyek látogatóinak összesített elemzését teszi lehetővé.'
            }
          },
          domain: ['.google.com', '.gstatic.com'],
          third_party: true,
          type: 'HTTP',
          expiry: {
            i18n: {
              hu: '1 hét'
            }
          },
          source: 'Google',
          policy_group: 'google'
        }
      ]
    }

  ],
  template(that) {
    return (
      '<div class="dwcc-inner">' +
        '{{components.box_simple}}' +
        '{{components.box_advanced}}' +
        '{{components.box_cookies}}' +
      '</div>'
    );
  },

  components: {
    cat_essential: {
      type: 'category',
      defaultValue: 'accepted',
      label: '{{i18n.cookie_essential_label}}',
      desc: '{{i18n.cookie_essential_desc}}'
    },

    cat_statics: {
      type: 'category',
      defaultValue: 'accepted',
      label: '{{i18n.cookie_performance_label}}',
      desc: '{{i18n.cookie_performance_desc}}'
    },

    cat_marketing: {
      type: 'category',
      defaultValue: 'accepted',
      label: '{{i18n.cookie_marketing_label}}',
      desc: '{{i18n.cookie_marketing_desc}}'
    },

    btn_accept: {
      type: 'button',
      action: 'accept',
      text(component) {
        const dwcc = component.dwCookieConsent;
        if (dwcc.savedState) {
          return '{{i18n.keepTheSettingsAndClose}}';
        }
        return '{{i18n.btn_accept}}';
      }
    },

    btn_reject: {
      type: 'button',
      action: 'reject',
      text: '{{i18n.btn_reject}}'
    },

    btn_close: {
      type: 'button',
      action: function(component) {
        const dwcc = component.dwCookieConsent;
        dwcc.hide();
      },
      className: 'close',
      visible(component) {
        const dwcc = component.dwCookieConsent;
        return !!dwcc.savedState;
      },
      title: '{{i18n.btn_close_tile}}',
      text: '{{i18n.btn_close}}'
    },

    btn_privacy: {
      type: 'button',
      action: 'native',
      className: 'link',
      inline: true,
      text: '{{i18n.btn_privacy}}',
      href: '{{i18n.dataPrivacyLink}}',
      target: '_blank'
    },

    btn_cookies: {
      type: 'button',
      className: 'link',
      action(component) {
        component.dwCookieConsent.getComponent('box_cookies').show('bysimple');
        component.dwCookieConsent.getComponent('box_simple').hide('bysimple');
      },
      text: '{{i18n.btn_cookies}}',
      inline: true
    },

    btn_advanced: {
      type: 'button',
      className: 'link',
      text: '{{i18n.btn_advanced}}',
      action(component) {
        component.dwCookieConsent.getComponent('box_advanced').show('bysimple');
        component.dwCookieConsent.getComponent('box_simple').hide('bysimple');
      }
    },

    btn_privacy_advanced: {
      type: 'button',
      action: 'native',
      className: 'link',
      inline: true,
      text: '{{i18n.btn_privacy_advanced}}',
      href: '{{i18n.dataPrivacyLink}}',
      target: '_blank'
    },

    btn_save_advanced: {
      type: 'button',
      text: '{{i18n.btn_save_advanced}}',
      action: 'accept'
    },

    btn_close_advanced: {
      type: 'button',
      action(component) {
        const dwcc = component.dwCookieConsent;
        const box_advanced = dwcc.getComponent('box_advanced');
        const box_simple = dwcc.getComponent('box_simple');
        // Close completely if the consent accepted and opened by a link.
        // if (!!dwcc.savedState && box_advanced.causes.show === 'bylink') {

        // Close completely if the consent accepted
        if (dwcc.savedState) {
          dwcc.hide();
        }
        else {
          box_advanced.hide('byadvanced');
          box_simple.show('byadvanced');
        }
      },
      className: 'close',
      title: '{{i18n.btn_close_tile}}',
      text: '{{i18n.btn_close}}'
    },

    btn_cookies_advanced: {
      type: 'button',
      inline: true,
      action(component) {
        component.dwCookieConsent.getComponent('box_cookies').show('byadvanced');
        component.dwCookieConsent.getComponent('box_advanced').hide('byadvanced');
      },
      // className: 'block-link',
      className: 'link',
      // inline: true,
      text: '{{i18n.btn_cookies}}'
    },

    btn_close_cookies: {
      type: 'button',
      action(component) {
        const dwcc = component.dwCookieConsent;
        const box_cookies = dwcc.getComponent('box_cookies');
        // Close completely if the consent accepted and opened by a link.
        if (!!dwcc.savedState && box_cookies.causes.show === 'bylink') {
          dwcc.hide();
        }
        else if (box_cookies.causes.show === 'byadvanced') {
          component.dwCookieConsent.getComponent('box_cookies').hide('bycookies');
          component.dwCookieConsent.getComponent('box_advanced').show('bycookies');
        }
        else {
          component.dwCookieConsent.getComponent('box_cookies').hide('bycookies');
          component.dwCookieConsent.getComponent('box_simple').show('bycookies');
        }
      },
      className: 'close',
      title: '{{i18n.btn_close_tile}}',
      text: '{{i18n.btn_close}}'
    },

    rng_consent_degree: {

      init(component) {
        const dwcc = component.dwCookieConsent;
        const categories = dwcc.settings.categories;
        let html = '';
        this.max = categories.length;

        for (let i = 0; i < this.max; i++) {
          html += this.categoryHtml(categories[i], i);
        }

        this.value = this.max; // todo saved consent

        if (dwcc.savedState) {
          const savedCat = categories.find(c => c.type === dwcc.savedState);
          if (savedCat) {
            this.value = categories.indexOf(savedCat) + 1;
          }
        }

        this.content = `
          <div class="dwcc-rangeslider-categories" data-active="${this.value}">
            ${html}
          </div>
          <div class="dwcc-rangeslider-details">
            <div>${categories[this.value - 1].detail}</div>

          </div>
        `;
      },
      categoryHtml(category, x) {
        return (
          `<div class="dwcc-rangeslider-category" data-val="${x + 1}">
            <div class="dwcc-rangeslider-label">${category.label}</div>
            <p>${category.desc}</p>
          </div>`
        );
      },
      events: {
        '.dwcc-rangeslider-category > *': {
          'click touchstart': function(e, elem, componentData) {
            const val = elem.parentNode.getAttribute('data-val');
            this.componentData.updateValue(this, val);
          }
        },
        'input.dwcc-rangeslider': {
          'change': function(e, elem) { this.componentData.updateValue(this); e.preventDefault(); },
          'input': function(e, elem) { this.componentData.updateValue(this); }
        }
      },
      updateValue(component, _newValue) {
        let el_input = component.elComponent.querySelector('input');
        if (typeof _newValue === 'string') {
          el_input.value = _newValue;
        }
        this.value = el_input.value;
        const categories = component.dwCookieConsent.settings.categories;
        const el_details = component.elComponent.querySelector('.dwcc-rangeslider-details div');
        const el_cat = component.elComponent.querySelector('.dwcc-rangeslider-categories');
        el_cat.setAttribute('data-active', this.value);
        el_details.innerHTML = component.renderer(
          categories[this.value - 1].detail
        );
      },
      type: 'rangeslider',
      value: 1,
      step: 1,
      min: 1,
      max: null,
      content: ''
    },

    box_simple: {
      type: 'box',
      // <small>{{components.btn_privacy}}</small> |

      content: (`
        {{components.btn_close}}
        <div class="dwcc-disclaimer-text">
          <h1>{{i18n.simpleHeadline}}</h1>
          <p>
            {{i18n.simpleDisclaimer}}

          </p>
        </div>
        <div class="dwcc-buttons dwcc-inline-childs">
          {{components.btn_accept}}
          {{components.btn_cookies}}
          {{components.btn_advanced}}
        </div>
        `
      )
    },

    box_advanced: {
      type: 'box',
      visible: false,
      events: {
        '{document}': {
          'keydown': function(e, elem) {
            if (this.visible && ((e.key + '').indexOf('Esc') > -1 || e.keyCode === 27)) {
              this.dwCookieConsent.getComponent('btn_close_advanced').callAction();
              e.stopPropagation();
            }
          }
        }
      },
      content: (
        '{{components.btn_close_advanced}}' +
        '<div class="dwcc-disclaimer-text">' +
          '<h1>{{i18n.advancedHeadline}}</h1>' +
          '<p>{{i18n.advancedDisclaimer}}</p>' +
        '</div>' +
        '<div class="dwcc-categories">' +
          '{{components.rng_consent_degree}}' +
        '</div>' +
        '{{components.btn_save_advanced}}' +
        '{{components.btn_privacy_advanced}}' +
        '{{components.btn_cookies_advanced}}'
      )
    },

    box_cookies: {
      type: 'box',
      visible: false,
      events: {
        '{document}': {
          'keydown': function(e, elem) {
            if (this.visible && ((e.key + '').indexOf('Esc') > -1 || e.keyCode === 27)) {
              this.dwCookieConsent.getComponent('btn_close_cookies').callAction();
              e.stopPropagation();
            }
          }
        },
        '{this}': {
          'click touch': function(e, elem) {
            if (e.target === elem) {
              this.dwCookieConsent.getComponent('btn_close_cookies').callAction();
            }
          }
        }
      },
      content: (
        '{{components.btn_close_cookies}}' +
        '<div class="dwcc-cookies-text">' +
          '{{i18n.cookiePolicy}}' +
        '</div>'
      )
    },

    cookie_table_cookies_essential: {
      type: 'cookie_table',
      content(component) {
        const dwcc = component.dwCookieConsent;
        const cat = dwcc.settings.categories.find(c => c.type === "essential");
        return cat ? cat : 'Category not found (essential)';
      }
    },

    cookie_table_cookies_functional: {
      type: 'cookie_table',
      content(component) {
        const dwcc = component.dwCookieConsent;
        const cat = dwcc.settings.categories.find(c => c.type === "functional");
        return cat ? cat : 'Category not found (functional)';
      }
    },

    cookie_table_cookies_performance: {
      type: 'cookie_table',
      content(component) {
        const dwcc = component.dwCookieConsent;
        const cat = dwcc.settings.categories.find(c => c.type === "performance");
        return cat ? cat : 'Category not found (performance)';
      }
    },

    cookie_table_cookies_marketing: {
      type: 'cookie_table',
      content(component) {
        const dwcc = component.dwCookieConsent;
        const cat = dwcc.settings.categories.find(c => c.type === "marketing");
        return cat ? cat : 'Category not found (marketing)';
      }
    },

    thirdparty_table: {
      type: 'policy_group_table'
    }
  },

  componentTypes: {

    category: {
      defaults: {
        label: '',
        desc: '',
        defaultValue: 'declined',
        extraClass: 'dwcc-clear'
      },
      extend(componentData) {

      },
      updateState() {
        const el = this.elComponent.querySelector("input[type=radio]:checked");
        this.state.value = el.value;
      },
      events: {
        '.switch-input': {
          'change': function(e, elem) { this.updateState(); }
        }
      },
      template: (
        //  @credit: https://codepen.io/oggyindahouse/pen/Bamui
        '<strong class="dwcc-cat-label">{{component.label}}</strong>' +
        '<div class="switch switch-white">' +
            '<input type="radio" class="switch-input" name="{{component.cid}}" value="accepted" id="{{component.cid}}-on" checked>' +
            '<label for="{{component.cid}}-on" class="switch-label switch-label-off">{{i18n.cat_on}}</label>' +
            '<input type="radio" class="switch-input" name="{{component.cid}}" value="declined" id="{{component.cid}}-off">' +
            '<label for="{{component.cid}}-off" class="switch-label switch-label-on">{{i18n.cat_off}}</label>' +
            '<span class="switch-selection"></span>' +
        '</div>'
      )
    },

    button: {
      defaults: {
        title: '',
        text: '',
        className: 'button',
        href: false,
        target: false
      },
      extend(componentData) {
        if (typeof componentData.text === 'function') {
          componentData.text = componentData.text(this);
        }
      },
      events: {
        '.dwcc-btn:not(.dwcc-native)': {
          'click touchstart': function(e, elem, componentData) {
            e.stopPropagation(); e.preventDefault();
            this.callAction();
          }
        }
      },
      template() {
        const data = this.componentData;
        const classes = [
          'dwcc-btn',
          'dwcc-{{component.className}}',
          'dwcc-{{component.cid}}'
        ];
        if (data.action === 'native') {
          classes.push('dwcc-native');
        }
        return (
          `<a
            ${data.href ? ' href="{{component.href}}"' : ''}
            ${data.target ? ' target="{{component.target}}"' : ''}
            class="${classes.join(' ')}"
            title="{{component.title}}">{{component.text}}
          </a>`
        );
      }
    },

    box: {
      template: (
        '<div class="dwcc-box dwcc-{{component.cid}}">{{component.content}}</div>'
      )
    },

    cookie_table: {
      extend() {
        const data = this.componentData;
        const dwcc = this.dwCookieConsent;
        const map = dwcc.settings.cookieTableMap;
        if (typeof data.content !== 'function') {
          return;
        }
        const category = data.content(this);
        if (!category || typeof category !== 'object') { return; }

        data.headCells = [];
        data.bodyRows = [];

        category.cookies.forEach((cookie, i) => {
          const row = [];
          for (const prop in map) {
            if (!map.hasOwnProperty(prop)) { continue; }
            if (i === 0) {
              // building header cells in the first cycle
              data.headCells.push({ prop: prop, content: map[prop], hl: true });
            }
            let val = cookie[prop];
            if (typeof val === 'object' && val && val.i18n) {
              val = val.i18n[dwcc.settings.lang] || val.i18n['en'] || '';
            }
            if ((prop === 'domain' || prop === 'source') && val === null) {
              val = window.location.hostname;
            }
            else if (prop === 'third_party') {
              val = val ? '●' : '';
            }
            if (Array.isArray(val)) {
              val = val.join(', ');
            }
            row.push({ prop: prop, content: val });
          }
          data.bodyRows.push(row);
        });
      },
      tpl_tr(cells, hl) {
        return `<tr>${cells.map(c => this.tpl_cell(c, hl)).join('')}</tr>`;
      },
      tpl_cell(cell, hl) {
        const tag = (hl || cell.hl) ? 'th' : 'td';
        return `<${tag} class="${cell.prop}">${cell.content}</${tag}>`;
      },
      template() {
        const data = this.componentData;
        let out;

        if (data.bodyRows.length) {
          out = (
            `<table>
                <thead>${this.tpl_tr(data.headCells, true)}</thead>
                <tbody>${data.bodyRows.map(r => this.tpl_tr(r)).join('')}</tbody>
            </table>
            `
          );
        }
        else {
          out = (
            `<p class="dwcc-cookie-table-empty-category"><i>{{i18n.table_empty_category}}</i></p>`
          );
        }
        return (
          `<div class="dwcc-table dwcc-cookie-table dwcc-{{component.cid}}">
            ${out}
          </div>`
        );
      }
    },

    policy_group_table: {
      extend() {
        const data = this.componentData;
        const dwcc = this.dwCookieConsent;
        const map = dwcc.settings.policyGroupTableMap;
        const groups = dwcc.settings.policyGroups;

        if (!groups || typeof groups !== 'object') { return; }

        data.headCells = [];
        data.bodyRows = [];
        let subrow = null;

        groups_loop:for (let i = 0; i < groups.length; i++) {
          const group = groups[i];
          const row = [];
          for (const prop in map) {
            if (!map.hasOwnProperty(prop)) { continue; }
            if (i === 0) {
              // building header cells in the first cycle
              let c = map[prop];
              if (c && typeof c === 'object' && c.content) { c = c.content; }
              if (c !== null && c !== false) {
                data.headCells.push({ prop: prop, content: c, hl: true });
              }
            }
            let val = group[prop];

            if (typeof val === 'object' && val && val.i18n) {
              val = val.i18n[dwcc.settings.lang] || val.i18n['en'] || '';
            }

            if (Array.isArray(val)) {
              val = val.join(', ');
            }
            if (prop === 'links') {
              val = this.tpl_links(val, map[prop]);
            }
            if (prop === 'policy_group') {
              const cookies = this.getCoociesByGroup(val);
              if (cookies.length) {
                val = cookies.join(', ');
              }
              else {
                continue groups_loop;
              }
            }
            if (prop === 'description') {
              if (val) {
                subrow = { prop, content: val };
              }
              continue;
            }
            row.push({ prop: prop, content: val });
          }
          data.bodyRows.push(row);
          if (subrow) {
            subrow.colSpan = data.headCells.length;
            data.bodyRows.push([subrow]);
            if (i + 1 < groups.length) {
              data.bodyRows.push(data.headCells);
            }
          }
          subrow = null;
        }
        if (data.bodyRows[data.bodyRows.length - 1] === data.headCells) {
          data.bodyRows.pop();
        }
      },
      getCoociesByGroup(group) {
        const found = [];
        const categories = this.dwCookieConsent.settings.categories;

        categories.forEach(category => category.cookies.forEach(c => {
          if (c.policy_group === group) {
            found.push(
              Array.isArray(c.pattern) ? c.pattern.join(', ') : c.pattern
            );
          }
        }));
        return found;
      },
      tpl_links(links, texts) {
        let out = [];
        for (let p in links) {
          if (links.hasOwnProperty(p)) {
            (Array.isArray(links[p]) ? links[p] : [links[p]]).forEach(
              url => out.push(
                `<a target="_blank" rel="nofollow noopener" href="${url}">${texts[p] || 'link'}</a>`
              )
            );
          }
        }
        return out.join('<br />');
      },
      tpl_tr(cells, hl) {
        return `<tr>${cells.map(c => this.tpl_cell(c, hl)).join('')}</tr>`;
      },
      tpl_cell(cell, hl) {
        const tag = (hl || cell.hl) ? 'th' : 'td';
        const collSpan = cell.colSpan ? ` colSpan="${cell.colSpan}"` : '';
        return `<${tag} ${collSpan}class="${cell.prop}">${cell.content}</${tag}>`;
      },
      template() {
        const data = this.componentData;
        const out = (
          `<div class="dwcc-table dwcc-cookie-table dwcc-{{component.cid}}">
            <table>
              <thead>${this.tpl_tr(data.headCells, true)}</thead>
              <tbody>${data.bodyRows.map(r => this.tpl_tr(r)).join('')}</tbody>
            </table>
          </div>`
        );
        return out;
      }
    },

    rangeslider: {
      template(a) {
        return (`
          <div class="dwcc-rangeslider-wrp vertical dwcc-{{component.cid}}">
            <input type="range" class="dwcc-rangeslider"
              value="{{component.value}}" step="{{component.step}}"
              min="{{component.min}}" max="{{component.max}}"
              />
          </div>
          {{component.content}}
        `);
      }
    }
  },

  onOpen(mode) {
    if (mode === 'cookies') {
      this.getComponent('box_simple').hide('bylink');
      this.getComponent('box_advanced').hide('bylink');
      this.getComponent('box_cookies').show('bylink');
    }
    else if (mode === 'advanced') {
      this.getComponent('box_simple').hide('bylink');
      this.getComponent('box_advanced').show('bylink');
      this.getComponent('box_cookies').hide('bylink');
    }
    else {
      this.getComponent('box_simple').show('bylink');
      this.getComponent('box_advanced').hide('bylink');
      this.getComponent('box_cookies').hide('bylink');
    }
  },

  onShow() {

  },

  onHide() {
    this.getComponent('box_simple').hide('byhiding');
    this.getComponent('box_advanced').hide('byhiding');
    this.getComponent('box_cookies').hide('byhiding');
  },

  onReady() {
    if (this.savedCategory) {
      this.settings._handleRestricted.call(
        this, this.settings.categories, this.savedCategory
      );
    }
  },

  _handleRestricted(categories, category) {
    if (!category) {
      void (window.console && console.log('Invalid category'));
      return;
    }
    const currentI = category === false ? -1 : categories.indexOf(category);
    const allowedLevels = categories.slice(0, currentI + 1).map(c => c.type);

    document.querySelectorAll('.dwcc-restricted_3rdparty-content').forEach(el => {
      const level = el.getAttribute('data-dwcc-level');
      if (el.classList.contains('do-reload') && level &&
        allowedLevels.indexOf(level) > -1
      ) {
        window.location.reload();
      }
      else if (el.classList.contains('do-remove') && level &&
        allowedLevels.indexOf(level) > -1
      ) {
        el.parentNode.removeChild(el);
      }
    });

    document.querySelectorAll('.dwcc-restricted_3rdparty-content-hidden').forEach(el => {
      const level = el.getAttribute('data-dwcc-level');
      if (level && allowedLevels.indexOf(level) > -1) {
        el.classList.remove('dwcc-restricted_3rdparty-content-hidden');
      }
    });

  },

  onAccepted(category, reloadInProgress) {
    this.settings._handleRestricted.call(this, this.settings.categories, category);

    if (reloadInProgress) { return; }

    document.querySelectorAll('.dwcc-restricted_3rdparty-content').forEach(el => {
      if (el.classList.contains('do-reload')) {
        window.location.reload();
      }
      else if (el.classList.contains('do-remove')) {
        el.parentNode.removeChild(el);
      }
    });

    document.querySelectorAll('.dwcc-restricted_3rdparty-content-hidden').forEach(el => {
      el.classList.remove('dwcc-restricted_3rdparty-content-hidden');
    });


    document.querySelectorAll('iframe').forEach(el => {
      let param = (el.src || '').match(/[&?]cookies?=[^&?#$]+/i);
      if (!param || !param[0]) { return; }

      param = param[0];
      let pa_ram = param.split('=');
      let newparam = pa_ram[0] + '=';

      if (pa_ram[1] === '0' || pa_ram[1] === '1') {
        // the prevoius value is likely numeric boolean
        newparam += (category.type === 'marketing') ? '1' : '0';
      }
      else {
        newparam += category.type;
      }
      el.src = el.src.replace(param, newparam);
    });
  },

  detectSoftOptIn(done) {
    const {util} = this;

    util.konsole.log('detectSoftOptIn: init');

    const selReject = this.settings.rejectConsentSelector || 'dwcc-reject';
    const selEdit = this.settings.editConsentSelector || 'dwcc-edit';
    const box_advanced = this.getComponent('box_advanced');
    const selectors = {
      exclusions: `[class*="dwcc-restricted"], .dwcc-cookieconsent *, ${selEdit}, ${selReject}`,
      clickables: 'a, button, label, [role="button"], input[type="button"]',
      focusable: 'input:not([type="button"]), select, textarea, [contenteditable="true"]'
    };

    const onUserActivity = (e, elem, unbind) => {
      if (
        (typeof e.isTrusted === "boolean" && !e.isTrusted) ||
        (window.CustomEvent && e instanceof window.CustomEvent)
      ) {
        util.konsole.log('detectSoftOptIn omitted: Not a human activity.');
        return;
      }
      if (elem.closest(selectors.exclusions)) {
        util.konsole.log('detectSoftOptIn omitted: elem is in the exclusions.');
        return;
      }
      if (box_advanced.visible) {
        util.konsole.log('detectSoftOptIn omitted: advanced panel is visible');
        return;
      }
      if (this.savedState !== null) {
        util.konsole.log('detectSoftOptIn omitted: Already consent.');
        unbind();
        return;
      }
      unbind();
      done();
      util.konsole.log('detectSoftOptIn: soft-accepted');
    };

    util.el_liveBind(document, 'click touchend', selectors.clickables, onUserActivity);
    util.el_liveBind(document, 'focusin', selectors.focusable, onUserActivity);
  },

  actions: {
    accept(component) {
      const dwcc = component.dwCookieConsent;
      const rngComponent = dwcc.getComponent('rng_consent_degree');
      const value = rngComponent.componentData.value + '';
      const category = dwcc.settings.categories[value - 1];
      if (!category || !category.type) {
        throw new Error('unexpected error during validation of range-slider value.');
      }
      dwcc.accept(category.type);
      //  category.type)
    },
    reject(component) {
      const dwcc = component.dwCookieConsent;
      dwcc.reject();
    },
    openlink(component) {
      if (this.href) {
        window.open(this.href, this.target);
      }
    }
  }
};
